html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  overflow: hidden;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

#root {
  overflow: hidden;
}
